<template>
  <div class="containerLabsPage pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Услуги
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-form-checkbox
              v-model="isActive"
              size="sm"
              @input="fetchServices()"
            >
              <span class="text-secondary">
                Активирована
              </span>
            </b-form-checkbox>

            <b-form-checkbox
              v-model="isMerged"
              size="sm"
              @input="fetchServices()"
            >
              <span class="text-secondary">
                Привязана
              </span>
            </b-form-checkbox>

            <b-form-checkbox
              v-model="isCheckup"
              size="sm"
              @input="fetchServices()"
            >
              <span class="text-secondary">
                Чекап
              </span>
            </b-form-checkbox>
          </div>

          <b-button
            :disabled="!checkFeatureAccess({ name: 'Добавить услугу', url: '/laboratory-service' })"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openAddServiceModal"
          >
            <b-icon icon="plus" />
            Добавить
          </b-button>
        </div>

        <div class="mt-4 mb-4 d-flex">
          <b-input-group>
            <b-form-input
              v-model="partnerServiceName"
              placeholder="По названию  услуги"
              @keyup.native.enter="fetchServices"
            />
            <b-form-input
              v-model="partnerServiceCode"
              placeholder="По коду услуги"
              @keyup.native.enter="fetchServices"
            />
          </b-input-group>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="fetchServices"
          >
            <b-icon icon="search" />
          </b-button>
        </div>

        <b-overlay
          v-if="!serviceListStore || Object.keys(serviceListStore).length === 0"
          :show="isLoading"
          no-wrap
        />

        <div v-if="serviceListStore">
          <div v-if="Object.keys(serviceListStore).length !== 0">
            <b-table
              v-if="serviceListStore"
              :fields="fields"
              :items="serviceListStore.data"
              :busy="isLoading"
              :per-page="0"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              :small="true"
            >
              <template v-slot:cell(isActive)="row">
                <b-badge
                  v-if="row.item.isActive"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Активирована
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не активирована
                </b-badge>
              </template>
              <template #cell(partnerServices)="row">
                <div
                  v-for="(item, idx) in row.item.partnerServices"
                  :key="idx"
                >
                  {{ item.name }}
                </div>
              </template>
              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  size="sm"
                  title="Обновить услугу"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  class="mr-2"
                  @click="openEditLaboratoryServiceModal(row)"
                >
                  <b-icon icon="pencil-fill" />
                </b-button>
                <b-button
                  v-if="row.item.isActive"
                  v-b-tooltip.hover
                  class="contact-legal-delete"
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  :disabled="!checkFeatureAccess({ name: 'Деактивировать услугу', url: '/laboratory-service' })"
                  size="sm"
                  title="Деактивировать"
                  @click="onClickDeActivateService(row)"
                >
                  <b-icon icon="person-x-fill" />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  class="contact-legal-delete mr-2"
                  variant="success"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Активировать"
                  @click="onClickActivateService(row)"
                >
                  <b-icon icon="person-check-fill" />
                </b-button>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="serviceListStore"
                class="crm-pagination"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${this.perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="+allCount"
                  :per-page="perPage"
                  style="padding-top: 16px"
                  @input="fetchServices"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { mixinRoles } from '@/mixins';
import { laboratoryService } from '@/services';

export default {
  name: 'LaboratoryService',
  page: {
    title: 'CRM Doctis - Услуги',
  },
  components: {
  },
  mixins: [mixinRoles],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Название Услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: ['nameClass', 'text-center'],
        },
        {
          key: 'code',
          label: 'Код услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'partnerServices',
          label: 'Привязанные партнерские услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'Статус услуги',
          tdClass: ['align-middle'],
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: ['actionsClass', 'text-center'],
        },
      ],
      selectedLegalCompanyId: null,
      legalCompanyOptions: [],
      isMerged: true,
      isCheckup: false,
      userData: null,
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      partnerList: [],
      isActive: true,
      partnerServiceName: null,
      partnerServiceCode: null,
      partnerServiceMinCost: null,
      partnerServiceMaxCost: null,
      allCount: null,
      // offSet: 0,
      limit: 10,
      preparation: [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: types.PARTNER_IS_LOADING_GET,
    }),
    serviceListStore() {
      return this.$store.state.Laboratories.serviceList;
    },
    paginationParams() {
      return {
        MinCost: this.partnerServiceMinCost,
        MaxCost: this.partnerServiceMaxCost,
        Code: this.partnerServiceCode,
        Name: this.partnerServiceName,
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
        isMerged: this.isMerged,
        isCheckup: this.isCheckup,
        LegalPartnerId: this.selectedLegalCompanyId,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchServices();
    await this.fetchPreparation();
    // await this.fetchLegalPartners();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.PARTNER_SERVICES_SET, null);
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchServices();
    },
    openImportServicesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ImportServices',
        props: {
          params: this.paginationParams,
        },
      });
    },
    async fetchPreparation() {
      const data = await laboratoryService.getPreparationList();
      this.preparation = [...data];
    },
    async fetchServices() {
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, this.paginationParams);
      this.allCount = data.xTotalCount;
    },
    async fetchLegalPartners() {
      this.legalCompanyOptions = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    },
    async onClickActivateService(row) {
      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_ACTIVATE, row.item.id);
      await this.fetchServices();
    },
    async onClickDeActivateService(row) {
      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_DEACTIVATE, row.item.id);
      await this.fetchServices();
    },
    openAddServiceModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddServiceModal',
        props: {
          preparation: this.preparation,
          params: this.paginationParams,
        },
      });
    },
    openEditLaboratoryServiceModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditLaboratoryServiceModal',
        props: {
          serviceId: row.item.id,
          preparation: this.preparation,
          params: this.paginationParams,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

::v-deep.crm-table {
  overflow: hidden;

  .nameClass{
    max-width: 30rem !important;
  }
  .actionsClass{
    min-width: 10rem !important;
  }
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.legalPartnerSelect{
  background-color: white;
}
</style>
